<template>
  <div class="index">
    <div class="nav">
      <van-icon name="arrow-left" style="color: #333333" size="0.6rem" @click="backBtn" />
      <van-search style="flex: 1" shape="round" placeholder="输入医学公式关键词进行搜索"  v-model="searchValue" @search="searchBtn" @clear="()=>{this.getRecommend();this.tagShow=true}" />

    </div>
    <div class="view" v-if="tagShow===true">
      <div>
        <div>
          历史记录
        </div>

        <div v-if="log.length!==0" style="display: flex;flex-wrap: wrap;margin-top: 10px">
          <div class="tag" v-for="(item,index) in log" :key="index" @click="tagBtn(item.keyword)">
            {{item.keyword}}
          </div>
        </div>
        <div v-else class="swipe_title_sub" style="margin-top: 8px">
          暂无搜索记录
        </div>
      </div>

      <div>
        <div style="margin: 30px 0">
          热门搜索
        </div>

        <div class="item_view_card" v-for="(item,index) in hots" :key="item.id" @click="routeBtn(item,'YXGS_SEARCH_HOT_LIST')">
          <div style="display: flex;">
            <div class="hot_index" :style="{ color: index === 0 ? '#F21448' : index === 1 ? '#D15C0E':index === 2 ? '#AB8111':'#000000' }">
              {{index+1}}
            </div>
            <div>
              <div class="swipe_title">
                {{item.chineseName}}
              </div>
              <div class="swipe_title_sub" >
                {{item.englishName}}
              </div>
            </div>
          </div>
          <div v-if="index!==hots.length-1" style=" border-bottom: #F6F6F6 solid 1px;margin: 10px 0" />

        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="list.length!==0">
        <div  class="card" v-for="item in list" :key="item.code" @click="routeBtn(item,'YXGS_SEARCH_RESULT_LIST')">
          <div>
            <div class="swipe_title">
              {{item.chineseName}}
            </div>
            <div class="swipe_title_sub" >
              {{item.englishName}}
            </div>
          </div>

          <van-icon name="arrow" />
        </div>
      </div>
      <van-empty v-else description="暂无内容" />

    </div>



  </div>
</template>


<script>


import {getMedicalRe, getMedicalSearch, getMedicalSearch_history} from "@/service/api";
import {Toast} from "vant";
import {postDataBrowse} from "@/service/api";
import { getAdmins } from "@/utils/adminDate";
export default {
  name: "medicalSearch",
  data(){
    return{
      tagShow:true,
      searchValue:'',
      list:[],
      hots:[],
      log:[],
      form:{},
    }
  },
  mounted() {
    this.form=this.$store.state.editForm
    this.getRecommend()
  },
  methods:{
    //热门推荐 / 历史搜索
    async getRecommend(){
      const res = await getMedicalRe()
      if (res.statuscode===200){
        this.hots=res.data
      }

      const log = await getMedicalSearch_history()
      if (log.statuscode===200){
        this.log=log.data
      }


    },

    async getList(){
      const res = await getMedicalSearch(this.searchValue,this.form.type)
      if (res.statuscode===200){
        this.list=res.data
      }
    },

    searchBtn(){
      if (this.searchValue===''){
        Toast('请输入关键字搜索');
        return
      }
      this.tagShow=false
      this.getList()
    },
    backBtn(){
      this.searchValue=""
      this.getRecommend()
      this.tagShow=true
      window.history.back();

    },
    tagBtn(item){
      this.tagShow=false
      this.searchValue=item
      this.getList()
    },
    routeBtn(item,type){

      this.$router.push({
        path:'/formula/'+item.code,
        query:{
          id:item.id,
          memberId:this.form.memberId,
          channel:this.form.channel,
          type:'h5',
          location:type,
        }
      });
       this.addClickRecord(item,type);
    },
    async addClickRecord(item,type) {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.form.memberId,
        this.form.channel
      );
      //点击埋点
      const data = {
        type: 'h5', //index表示在首页列表中点击，mix_index表示在专题列表中点击
        contentId: item.id, //为vod时，根据contentId增加浏览量
        contentType: "YXGS_DWHS",
        location: type,
        channel: this.form.channel,
        os: arrays.os,
        memberId:  this.form.memberId,
      };
      console.log(data);
      await postDataBrowse(data)
    },

  }
}
</script>



<style scoped lang="scss">
.index{
  font-size: 16px;
  color: #333333;
  height: auto;
  .nav{
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid #F1F1F1;
  }
  .view{
    margin-top: 10px;
    padding: 15px;
    min-height: 100vh;
    background-color: white;
  }
  .tag{
    min-width: 20px;
    text-align: center;
    margin:5px  5px 5px 0;
    font-size: 14px;
    color: #FFFFFF;
    background: #CCCBCB;
    border-radius: 23px;
    padding: 5px 10px;
  }
  .swipe_title{
    display:-webkit-box;
    /* 表示几行后超出隐藏 */
    -webkit-line-clamp:2;
    /* 超出隐藏 */
    overflow:hidden;
    /*超出显示为省略号*/
    text-overflow:ellipsis;
    -webkit-box-orient:vertical;
    /*强制英文单词自动换行*/
    word-break:break-all;
  }
  .swipe_title_sub{
    margin-top: 5px;
    color: #999999;
    font-size: 13px;
    display:-webkit-box;
    /* 表示几行后超出隐藏 */
    -webkit-line-clamp:1;
    /* 超出隐藏 */
    overflow:hidden;
    /*超出显示为省略号*/
    text-overflow:ellipsis;
    -webkit-box-orient:vertical;
    /*强制英文单词自动换行*/
    word-break:break-all;
  }
  .card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 15px;
    border-bottom: #F6F6F6 1px solid;
  }
  .item_view_card{
    align-items: center;
    padding-bottom: 10px;
    width: 100%;
  }
}
.hot_index{
  width: 20px;
  margin-right: 2px;
  padding-top: 2px;
  font-weight: bold;
  font-size: 17px;
  //font-family: Adobe Heiti Std;
}
</style>